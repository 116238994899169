.App {
  text-align: center;
  overflow-x: hidden;
  background-image: url('./assets/site-background.jpeg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.footer {
  background-image: url('./assets/footer.jpeg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}